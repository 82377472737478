import React from 'react';
import PropTypes from 'prop-types';
import {NavbarLink} from './navbarLink';
import {NewPermissionGate} from '../../permissions/permissionGate';
import * as allConstants from '../../constants/allConstants';
import {urlSearchParams} from '../../baseUrls';
import {LinkWrapper} from './linkWrapper';
import {NavbarMenuButton} from './navbarMenuButton';
import {jobRules, IPERMIT_JOB_ELEMENTS} from '../../permissions/ipermitAccessControl/index';
import {productsPageRules, ACCOUNTING_ELEMENTS} from '../../permissions/accountingAccessControl';
import {tagsPageRules, TAG_ELEMENTS} from '../../permissions/tagAccessControl';
import {companyRules, COMPANY_ELEMENTS} from '../../permissions/companyAccessControl';
import {contractorUserPageRules, CONTRACTOR_ELEMENTS} from '../../permissions/contractorUserPageAccessControl';
import {cityRules, CITY_ELEMENTS} from '../../permissions/cityAccessControl';
import {ratersPageRules, RATER_ELEMENTS} from '../../permissions/raterPageAccessControl';
import {CONTRACTOR_JOB_ELEMENTS, contractorJobRules} from '../../permissions/jobsAccessControl';
import {ipermitAdminRules, ADMIN_ELEMENTS} from '../../permissions/ipermitAdminAccessControl';



const AllLinks = (props)=> {
  const companyId = props.user?.companyId;

  let adminPanelLinks = [];

  if(NewPermissionGate({
    user: props.user,
    elementPermissions: tagsPageRules[TAG_ELEMENTS.addTagButton]
  })) {
    adminPanelLinks.push({
      alt: 'tags',
      text: 'All Tags',
      path: allConstants.PATH.TAGS,
    });
  }
  if(NewPermissionGate({
    user: props.user,
    elementPermissions: ratersPageRules[RATER_ELEMENTS.viewPage]
  })) {
    adminPanelLinks.push({
      alt: 'raters',
      text: 'Raters',
      path: allConstants.PATH.RATERS,
    });
  }
  if(NewPermissionGate({
    user: props.user,
    elementPermissions: ipermitAdminRules[ADMIN_ELEMENTS.adminPanel]
  })) {
    adminPanelLinks.push({
      alt: 'users',
      text: 'iPermit Users',
      path: allConstants.PATH.ADMIN_PAGE,
    });
    adminPanelLinks.push({
      alt: 'analytics',
      text: 'Analytics',
      path: allConstants.PATH.ANALYTICS,
    });
  }

  if(props.showIpermitLinks){
    return (
      <>

        <NewPermissionGate user={props.user} elementPermissions={IPERMIT_JOB_ELEMENTS.viewPermits}>
          <LinkWrapper>
            <NavbarMenuButton buttonContent={'Permits'}
              startIcon={{
                alt: 'permits',
                icon: 'two_pages'
              }}
              module={allConstants.IPERMIT_MODULES.PERMIT}
              links={[
                {
                  alt: 'permits',
                  text: 'All Jobs',
                  submodule: allConstants.IPERMIT_MODULES.ALL_JOBS,
                },
                {
                  alt: 'permits',
                  text: 'CF1R',
                  submodule: allConstants.IPERMIT_MODULES.CF1R,
                },
              ]}
            >
            </NavbarMenuButton>
          </LinkWrapper>
        </NewPermissionGate>
        <NewPermissionGate user={props.user} elementPermissions={jobRules[IPERMIT_JOB_ELEMENTS.viewInspections]}>
          <NavbarMenuButton buttonContent={'Inspections'}
              startIcon={{
                alt: 'inspections',
                icon: 'list'
              }}
              module={allConstants.IPERMIT_MODULES.INSPECTION}
              links={[
                {
                  alt: 'inspections',
                  text: 'Inspections',
                  path: `${allConstants.PATH.MODULE}?${urlSearchParams.moduleName}=${allConstants.IPERMIT_MODULES.INSPECTION}`
                },
                {
                  alt: 'schedule',
                  text: 'Schedule',
                  path: allConstants.PATH.INSPECTION_SCHEDULE,
                }
              ]}
            >
            </NavbarMenuButton>

        </NewPermissionGate>
        {/* <NewPermissionGate user={props.user} elementPermissions={jobRules[IPERMIT_JOB_ELEMENTS.viewInspections]}>
          <LinkWrapper>
            <NavbarLink path={`${allConstants.PATH.MODULE}?${urlSearchParams.moduleName}=${allConstants.IPERMIT_MODULES.INSPECTION}`} alt={'inspections'} icon={'list'} text={'Inspections'}/>
          </LinkWrapper>
        </NewPermissionGate> */}

        <NewPermissionGate user={props.user} elementPermissions={companyRules[COMPANY_ELEMENTS.allCompaniesNavLink]}>
          <LinkWrapper>

            <NavbarMenuButton buttonContent={'Companies'}
              drowdownWidth={'200px'}
              startIcon={{
                alt: 'companies',
                icon: 'briefcase'
              }}
              module={allConstants.IPERMIT_MODULES.COMPANIES}
              links={[
                {
                  alt: 'companies',
                  text: 'All Companies',
                  path: allConstants.PATH.ALL_COMPANIES,
                },
                {
                  alt: 'users',
                  text: 'Contractor Users',
                  path: allConstants.PATH.USERS,
                },
                // {
                //   alt: 'schedule',
                //   text: 'Inspection Schedule',
                //   path: allConstants.PATH.INSPECTION_SCHEDULE,
                // }
              ]}
            >
            </NavbarMenuButton>

            {/*<NavbarLink alt={'all companies'} icon={'briefcase'}  text={'All Companies'} path={allConstants.PATH.ALL_COMPANIES}/>*/}
          </LinkWrapper>
        </NewPermissionGate>


        <NewPermissionGate user={props.user} elementPermissions={cityRules[CITY_ELEMENTS.viewCity]}>
          <LinkWrapper>
            <NavbarLink alt={'cities'}
              icon={'city'}
              text={'Cities'}
              path={allConstants.PATH.CITIES}/>
          </LinkWrapper>
        </NewPermissionGate>

        <NewPermissionGate user={props.user}
          elementPermissions={productsPageRules[ACCOUNTING_ELEMENTS.viewProducts]}>
          <LinkWrapper>
            <NavbarLink alt={'products'} icon={'products'} text={'Products'} path={allConstants.PATH.PRODUCTS}/>
          </LinkWrapper>
        </NewPermissionGate>
        {/* <NewPermissionGate user={props.user}
          elementPermissions={tagsPageRules[TAG_ELEMENTS.addTagButton]}>
          <LinkWrapper>
            <NavbarLink alt={'tags'} icon={'products'} text={'Tags'} path={allConstants.PATH.TAGS}/>
          </LinkWrapper>
        </NewPermissionGate>
        <NewPermissionGate user={props.user} elementPermissions={ratersPageRules[RATER_ELEMENTS.viewPage]}>
          <LinkWrapper>
            <NavbarLink alt={'Raters'} icon={'users'} text={'Raters'} path={allConstants.PATH.RATERS}/>
          </LinkWrapper>
        </NewPermissionGate> */}
        {/* <NewPermissionGate user={props.user} elementPermissions={companyRules[COMPANY_ELEMENTS.allCompaniesNavLink]}> */}
        {adminPanelLinks.length  > 0 &&  <LinkWrapper>
            <NavbarMenuButton buttonContent={'Admin Panel'}
              drowdownWidth={'200px'}
              startIcon={{
                alt: 'admin',
                icon: 'briefcase'
              }}
              module={allConstants.IPERMIT_MODULES.ADMIN}
              links={adminPanelLinks}
            >
            </NavbarMenuButton>
          </LinkWrapper>
        }
        {/* </NewPermissionGate> */}
        {/* <LinkWrapper>
          <NavbarLink alt={'analytics'}
            icon={'piechart'}
            text={'Analytics'}
            path={allConstants.PATH.ANALYTICS}/>
        </LinkWrapper> */}
      </>
    );
  }

  return (
    <>
      <NewPermissionGate user={props.user} elementPermissions={contractorJobRules[CONTRACTOR_JOB_ELEMENTS.createJob]}>
        <LinkWrapper>
          <NavbarLink alt={'create_job'} icon={'plus'} text={'Create job'} path={allConstants.PATH.CREATE_JOB}/>
        </LinkWrapper>
      </NewPermissionGate>
      <LinkWrapper>
        <NavbarLink alt={'job'} icon={'list'} text={'Jobs'} path={allConstants.PATH.JOBS}/>
      </LinkWrapper>
      <LinkWrapper>
        <NavbarLink alt={'schedule'} icon={'list'} text={'Schedule'} path={allConstants.PATH.INSPECTION_SCHEDULE}/>
      </LinkWrapper>
      <NewPermissionGate user={props.user} elementPermissions={companyRules[COMPANY_ELEMENTS.companyNavLink]}>
        <LinkWrapper>
          <NavbarLink alt={'company'} icon={'briefcase'}  text={'Company'}
            path={companyId ? `${allConstants.PATH.COMPANY}?${urlSearchParams.companyId}=${companyId}`: allConstants.PATH.COMPANY}
          />
        </LinkWrapper>
      </NewPermissionGate>

      <NewPermissionGate user={props.user}
        elementPermissions={contractorUserPageRules[CONTRACTOR_ELEMENTS.viewPage]}
      >
        <LinkWrapper>
          <NavbarLink alt={'users'} icon={'users'} text={'Users'} path={allConstants.PATH.USERS}/>
        </LinkWrapper>
      </NewPermissionGate>

      <LinkWrapper>
        <NavbarLink alt={'analytics'}
          icon={'piechart'}
          text={'Analytics'}
          path={allConstants.PATH.ANALYTICS}/>
      </LinkWrapper>
      {/*</PermissionGate>*/}


    </>
  );
};

AllLinks.propTypes = {
  user: PropTypes.shape({
    role: PropTypes.string,
    companyId: PropTypes.string,
  }),
  companyId: PropTypes.string,
  showIpermitLinks: PropTypes.bool,
};
AllLinks.defaultProps = {
  user: {
    role: allConstants.ROLES.CONTRACTOR,
    companyId: null,
  },
  showIpermitLinks: false,
};

export {AllLinks};
