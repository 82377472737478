import React, { useState, useEffect, useRef} from 'react'
import moment from 'moment'
import {useSelector} from 'react-redux';
import base_url from '../../baseUrls';
import axios from 'axios'
import ApiUtils from '../../utils/apiUtils';
import { ScheduleComponent, ResourcesDirective, ResourceDirective, ViewsDirective, ViewDirective, Day, Week, Month, ExcelExport, Inject } from '@syncfusion/ej2-react-schedule';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
//import { extend, Internationalization, isNullOrUndefined, closest } from '@syncfusion/ej2-base';
//import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { AppBarComponent } from '@syncfusion/ej2-react-navigations';
//import { DropDownButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import { DropDownListComponent, AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import { DataManager, Query, UrlAdaptor, WebApiAdaptor } from '@syncfusion/ej2-data';
import '@syncfusion/ej2-base/styles/material.css';  // Import the theme CSS
import {USER_FIELDS} from '../../constants/allConstants';
import {isContractor, isImpersonating} from '../../permissions/utils';

class CustomUrlAdaptor extends UrlAdaptor {

    // // Override the `beforeSend` method
    // async beforeSend(request) {
    //     return request;
    // }

    // Override the processResponse method
    processResponse(data, success) {

        //console.log("return data => ", data);
        if (success) {
           return data.data;
        } else {
           // Handle errors or failed requests
           console.error('Request failed:', data);
           return [];
        }
    }
}

const  ScheduleWrapper  =  (props)  => {

    let scheduleObj = useRef(null);
    let dialogInstance = useRef(null);
    let authToken = useRef(null);
    const user = useSelector(state=> state.user);
    const [currentDate, setCurrentDate] = useState(moment());
    const [jobList, setJobList] = useState([]);  
    const [currentJobId, setCurrentJobId] = useState(null);
    const [currentJob, setCurrentJob] = useState(null);
    const [companyList, setCompanyList] = useState([]);
    const [selectedCompanyId, setSelectedCompanyId] = useState('');
    const [visibleDialog, setVisibleDialog] = useState(false);
    const [subject, setSubject] = useState('');
    const [startTime, setStartTime] = useState(null);
    const [dialogKey, setDialogKey] = useState(0);
    //const [authToken, setAuthToken] = useState();
    const [companyDataMgr, setCompanyDataMgr] = useState([]);
    const [companySearch, setCompanySearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [companySelectable, setCompanySelectable] = useState(true); 
    const minLength = 3; // Minimum number of characters before search

    const queryCompany = new Query().take(10).skip(0);

    useEffect ( async () => {

        const role = user?.[USER_FIELDS.ROLE.api_name];
        if(isContractor(role) || isImpersonating(role, user?.[USER_FIELDS.COMPANY_ID.api_name])) {
            setCompanySelectable(false);

            console.log('company dropdown is hidden');
            return;
        }
     
        authToken.current = await ApiUtils.getAccessToken();
        const urlCompany = `${base_url.api}companies/autocomplete`;

        const compDataMgr = new DataManager({
            url: urlCompany,
            adaptor: new CustomUrlAdaptor(),
            crossDomain: true,
            offline: false,
            headers: [ {'Authorization': authToken.current}],
            onFailure: (e) => {
                console.error('API call failed', e);
            }
        });
        setCompanyDataMgr(compDataMgr);

    }, [])

    const onChangeCompany = (args) => {

        console.log('inside on change company');
        console.log(args);

        if (args.itemData) {
            setSelectedCompanyId(args.itemData.companyId);
           
        }
        else {
            setSelectedCompanyId('')
        }
    };

    const handleFiltering = (e) => {

        console.log('current company data');
        console.log(companyData);

        if (e.text.length >= minLength) {
            setCompanySearch(e.text);
        }


        // const query = new Query().take(100).skip(0)
        //     .where('companyName', 'contains', e.text, true);  // Filter condition

        // e.updateData(companyData, query);  // Dynamically update data based on input
    };


    const fetchScheduledJobs = async (startDate, endDate, selCompanyId) => {

        const model = {
            startDate: Utils.formatIsoDateString(startDate, {date: true}),
            endDate: Utils.formatIsoDateString(endDate, {date: true}),
            companyId: selCompanyId,
        };

        //console.log('send model to server => ');
        //console.log(model);

        const authToken = await ApiUtils.getAccessToken();
        const url = `${base_url.api}ipermit/jobs/city-inspection/get-inspections-by-sched-dates`;
        const response = await axios.post(url, model, {headers: {Authorization: authToken}});


        // axios.post(url, body, {headers: {Authorization: authToken,}}).then (() => {
        //     curRowData.current.node.setDataValue(props.fieldPath, selValue);
        //     //props.okAction(assignee);
        //   });
        //console.log('response from server');
        //console.log(response);

        if (response.status === 200 && response.data) {
            setJobList(response.data.data);
        }
    };

    useEffect(() => {

        if (currentJob == null) {
            return;
        }

        setSubject(currentJob.Subject);
        setStartTime(currentJob.StartTime);       
        setVisibleDialog(true);

        //setTimeout(() => {
        //    setVisibleDialog(true);
        //}, 5000);        

    }, [currentJob])

    const fetchSelectedJob = async (jobId) => {

        // const response = await axios.post('/permit/getScheduledJobById', { jobId: jobId });

        // if (response.status === 200 && response.data) {

        //     console.log('result from selected event');
        //     console.log(response.data.data);

        //     setCurrentJob(response.data.data);
        // }
    };

    useEffect(() => {

        console.log('selected company id => ', selectedCompanyId);

        if (selectedCompanyId) {

            const dates = scheduleObj.current.getCurrentViewDates();
            fetchScheduledJobs(dates[0], dates[dates.length - 1], selectedCompanyId);
        }
    }, [selectedCompanyId]);

    useEffect(() => {
        if (visibleDialog) {
            dialogInstance.current.show();
            setDialogKey(prev => prev + 1);
        }
        else {
            dialogInstance.current.hide();
        }
    }, [visibleDialog])

    const dlgBtnClose = () => {
        setVisibleDialog(false);
    };

    const dlgButtons = [{ click: dlgBtnClose, buttonModel: { content: 'Close', isPrimary: true } }];

    const dlgContent = (data) => {
        return (<table className="custom-event-editor" style={{ width: '100%' }} cellPadding={5}>
            <tbody>
                <tr>
                    <td className="e-textlabel">Job</td>
                    <td colSpan={4}>
                        {subject}
                    </td>
                </tr>
                {/* <tr>
                    <td className="e-textlabel">Date:</td>
                    <td colSpan={4}>
                        <DateTimePickerComponent id="StartTime" format='dd/MM/yy' data-name="StartTime" value={new Date(startTime)} className="e-field" readonly />
                    </td>
                </tr>
                <tr>
                    <td className="e-textlabel">Description:</td>
                    <td colSpan={4}>
                        {expenseDesc}
                    </td>
                </tr>
                <tr>
                    <td className="e-textlabel">Contact(s):</td>
                    <td colSpan={4}>
                        {contacts}                        
                    </td>
                </tr>
                <tr>
                    <td className="e-textlabel">Message:</td>
                    <td colSpan={4}>
                        <audio controls src={listenBlobUrl} style={{ width: '100%', height: '50px'}} />
                    </td>
                </tr> */}
            </tbody>
        </table>);
    };

    const onPopupOpen = async (args) => {
        
        //console.log('popup args');
        //console.log(args);

        if (args.type === "Editor") {
            args.cancel = true;  
            
            if (!args.data.Id) {
                console.log('no ID');
                return;
            }

            //https://ipermitpro.com/module/66cf6aa4d1b68aff617eb184?moduleName=inspection&items=100&page=1&showSameAddressJobs=true&openTab=Call+Logs
            
            const url = `${location.origin}/module/${args.data.Id}?moduleName=inspection&showSameAddressJobs=true`;
    
            //console.log('url => ' + url);
            
            window.open(url, '_blank');

            //await fetchSelectedJob(args.data.jobId);          
        }
    };


    const onCreated = () => {

        console.log('on created');
        console.log('current company id => ', selectedCompanyId);

        if (selectedCompanyId) {

            console.log('on created fetch');

            const dates = scheduleObj.current.getCurrentViewDates();

            fetchScheduledJobs(dates[0], dates[dates.length - 1], selectedCompanyId);
        }

    }

    const onActionBegin = (args) => {
        if (args.requestType === 'toolbarItemRendering') {
            let exportItem = {
                align: 'Right', showTextOn: 'Both', prefixIcon: 'e-icons e-export-excel',
                text: 'Excel Export', cssClass: 'e-excel-export', click: onExportClick.bind(this)
            };
            args.items.push(exportItem);
        }
    };

    const onExportClick = () => {

        console.log('current events');
        console.log(scheduleObj.current.getEvents());
        console.log('job list');
        console.log(jobList);

        const exportFields = [
            { name: 'Subject', text: 'Address' },
            { name: 'StartTime', text: 'Scheduled Date' },
            { name: 'Location', text: 'Job Number'},
            // { name: 'Description', text: 'Description' },
            // { name: 'Contacts', text: 'Contact(s)' }
        ];
        const exportValues = { fieldsInfo: exportFields };
        scheduleObj.current.exportToExcel(exportValues);
    };

    const onActionComplete = (args) => {

        if (args.requestType == "viewNavigate" || args.requestType == "dateNavigate") {

            const dates = scheduleObj.current.getCurrentViewDates();

            console.log('fetch from onActionComplate');
            fetchScheduledJobs(dates[0], dates[dates.length-1], selectedCompanyId);
        }
    }

    return (
        <>
            <div id='mainContainer'>  
                <AppBarComponent colorMode="Primary">
                    {companySelectable &&
                    <div>
                        <span className="regular">Company: </span>
                        <div className='control-panel'>
                           {companyDataMgr && <AutoCompleteComponent id="company"  sortOrder="Ascending"
                               dataSource={companyDataMgr} query={queryCompany}
                               fields={{ value: 'companyName', text: 'companyName'}} 
                               
                        
                               //autofill={true} placeholder="" suggestionCount={100}
                               minLength={3} filterType='Contains'
                               change={onChangeCompany}
                               style={{ backgroundColor: 'white', color: 'black', width: '450px', marginLeft: '8px', paddingLeft: '2px' }}
                               
                               //filtering={handleFiltering}
                              />
                            }
                        
                        </div>
                    </div>  
                    }                                    
                    <div className="e-appbar-spacer"></div>                  
                    {/* <div style={{ whiteSpace: 'nowrap', marginTop: '5px'} }>
                        <span>Current User:&nbsp;&nbsp;</span><span style={{ color: 'yellow'} }>{username}</span>
                    </div>                                             */}
                </AppBarComponent>
                <ScheduleComponent ref={scheduleObj} width='100%' height='650px' selectedDate={currentDate} eventSettings={{ dataSource: jobList }}
                    currentView={'Month'} created={onCreated} actionBegin={onActionBegin} actionComplete={onActionComplete}
                    popupOpen={onPopupOpen} showQuickInfo={true}
                >
                    <ViewsDirective>
                        <ViewDirective option='Day' />
                        <ViewDirective option='Week' />
                        <ViewDirective option='Month' />
                    </ViewsDirective>
                    <Inject services={[Day, Week, Month, ExcelExport]} />
                </ScheduleComponent>
                <DialogComponent id="defaultdialog" key={dialogKey}  showCloseIcon={true} animationSettings={{ effect: 'None' }} width={'500px'} visible={visibleDialog}
                    ref={dialogInstance} target={'#mainContainer'} header={"Call Detail"} buttons={dlgButtons} content={dlgContent}>
                </DialogComponent>
            </div>
        </>

    );

}

export default ScheduleWrapper;


//<div>
//    <span>Visible Date Range</span> &nbsp;&nbsp;
//    <span>Start: {visibleRange.start.toString()}</span> &nbsp;&nbsp; == &nbsp;
//    <span>End: {visibleRange.end.toString()}</span> &nbsp;&nbsp;
//    <span>Selected date: {selectedDate}</span>
//</div>



//const getVisibleRange = (args) => {

//    console.log('inside getvisiblerange');
//    console.log(args);

//    const view = args.view;
//    const startDate = args.startDate;
//    const endDate = args.endDate;

//    let firstDay, lastDay;

//    switch (view) {
//        case 'Month':
//            firstDay = startDate;
//            lastDay = endDate;
//            break;
//        case 'Week':
//        case 'WorkWeek':
//            firstDay = startDate;
//            lastDay = new Date(startDate);
//            lastDay.setDate(startDate.getDate() + 6); // Week view shows 7 days
//            break;
//        case 'Day':
//            firstDay = startDate;
//            lastDay = new Date(startDate);
//            break;
//        default:
//            firstDay = startDate;
//            lastDay = endDate;
//    }

//    setVisibleRange({ start: firstDay, end: lastDay });
//};


    //const fetchProspectList = (searchText) => {

    //    console.log('search text');
    //    console.log(searchText);

    //    if (searchText.length <= 2) {
    //        return;
    //    }
    //    const query = new Query().take(20).skip(0).where('name', 'contains', searchText);

    //    prospectData.executeQuery(query)
    //        .then((response) => {
    //            setProspectList(response.result);
    //        })
    //        .catch((error) => {
    //            alert('Error fetching prospect data:', error);
    //        });

    //};

    // const fetchUserList = async () => {

    //     const result = await axios.post(`/proxyapi/getUserList/?userId=${userId}`);
    //     if (result.status === 200 && result.data) {
    //         setUserList(result.data);        

    //         setDefaultUserId(userId);

    //         const user = result.data.find(x => x.userId == userId);

    //         if (user != null) {
    //             setUsername(user.fullname);
    //         }

    //     }
    // };

    //const fetchProspectList = async () => {

    //    const result = await axios.post('/proxyapi/getProspectList');

    //    if (result.status === 200 && result.data) {

    //        console.log('result for prospect list');
    //        console.log(result.data);

    //        setProspectList(result.data);
    //    }
    //}

