import React, {useEffect, useState} from 'react';
import {Box, ThemeProvider} from '@mui/material';
import NavBar from '../../pieces/navbar/navbar';
import myTheme from '../../styles/myStyles';
import {useNavigate} from 'react-router-dom';
import {globalUseClasses} from '../../styles/globalClasses';
import ScheduleWrapper from '../../pieces/calendars/scheduleWrapper';

const InspectionScheduleView = () => {

  //const viewController = useGridViewState();
  const navigate = useNavigate();
  const classes = globalUseClasses();

  // const headerComponent = <ListPageHeader header={'All Companies'}
  //   addHandler={()=>{navigate(`${allConstants.PATH.REGISTER_COMPANY}?${urlSearchParams.invite}=true`);}}
  //   showAddButton = {NewPermissionGate({
  //     user: viewController.user,
  //     elementPermissions: companyRules.inviteButton,
  //   })}

  //   totalItems={pagination.totalItemsCount}
  // >
  //   {pagination.ItemsController}
  // </ListPageHeader>;


  return (
    <>
      <NavBar/>
      <ThemeProvider theme={myTheme}>
        <Box className={classes.page}>
          <ScheduleWrapper />
        </Box>
      </ThemeProvider>
    </>
  );
};
export default InspectionScheduleView;
