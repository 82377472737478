import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {StyledEngineProvider, CssBaseline, Box} from '@mui/material';
import {Route, Routes, BrowserRouter} from 'react-router-dom';
import {makeStyles} from '@mui/styles';
import {ThemeProvider} from '@mui/material/styles';
import myTheme from './styles/myStyles';
import {LicenseManager} from 'ag-grid-enterprise';
import * as allConstants from './constants/allConstants';
import PrivateRoute from './router/privateRouter';
import PrivateiPermitRoute from './router/privateiPermitRouter';
import AnonymousRoute from './router/anonymousRoute';
import Home from './pages/home/home';
import AllUsersView from './pages/users/allUsersView';
// import Company from './pages/companies/company';
import SignupPage from './pages/auth/signupPage';
import DevWindowBindings from './devWindowBindings';
import LoginPage from './pages/auth/loginPage';
import AccessDenied from './pages/misc/accessDenied';
import actions from './redux/actions';
import ServerRedirect from './router/serverRedirect';
import {CustomToast} from './pieces/toastNotification';
import AllCompaniesView from './pages/companies/allCompaniesView';
import AdminPage from './pages/adminPage/admipPageView';
import BlockedPage from './pages/misc/blockedCompany';
import PackageJson from '../package.json';
import ApiUtils from './utils/apiUtils';
import CreateJobView from './pages/jobs/createJob/createJobView';
import AllJobsView from './pages/jobs/allJobs/allJobsView';

import base_url from './baseUrls';
import axios from 'axios';
import AnalyticsView from './pages/analytics/analytics';
import NoAccess from './pages/misc/noAccess';
import {RegisterCompany} from './pages/companies/registerCompany';
import AllProductsView from './pages/products/allProductsView';
import AllTagsView from './pages/tags/allTagsView';
import {CompanyView} from './pages/companies/companyView';
import CompanyExists from './pages/misc/companyAlreadyExists';
import CompanyUnderReview from './pages/misc/companyUnderReview';
import {ModuleView} from './pages/jobs/ipermitJobPages/moduleView';
import {IpermitJobView} from './pages/jobs/ipermitJobPages/ipermitJobView';
import {AllCitiesView} from './pages/cities/allCitiesView';
import {CityView} from './pages/cities/cityView';
import CheersConnectionRequiredPage from './pages/misc/cheersConnectionRequired';
import {UserView} from './pages/users/userView';
import {IpermitUserView} from './pages/adminPage/ipermitUserView';
import AllRatersView from './pages/raters/allRatersView';
import {RaterView} from './pages/raters/raterView';

import InspectionScheduleView from './pages/calendars/inspectionScheduleView';

const fetchVersion = async () => {
  let url = `${base_url.api}version`;
  const response = await axios.get(url);
  return response;
};

window.getVersion = async ()=> {
  console.log(`Frontend version::: ${PackageJson.version}`);
  const backEndVersion = await fetchVersion();
  if(backEndVersion.status === 200) {
    console.log('Backend version:::', backEndVersion?.data);
  } else {
    console.log('Failed to get backend version');
  }
};

const useStyles = makeStyles((theme) => ({
  page_container: {
    height: '100vh',
    fontFamily: ['DM Sans', 'sans-serif'].join(','),
    backgroundColor: '#fff'
  },
}));
LicenseManager.setLicenseKey('CompanyName=Bits, Bytes and Such Inc,LicensedGroup=Bits, Bytes and Such Inc.,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-030338,SupportServicesEnd=15_July_2023_[v2]_MTY4OTM3NTYwMDAwMA==5f79dd41870f810b175183963c8b60df');
const App = ()=> {
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(()=> {

    async function fetchUser(){
      await dispatch(actions.getCurrentUserData());
    }
    if(!ApiUtils.isMockMode()){
      console.log('will request user data',);
      fetchUser();
    }

  });
  /* built-in .flat() function is not supported by all browsers (eg. Safari), so no support, extend support */
  if (!Array.prototype.flat) {
    // eslint-disable-next-line no-extend-native
    Object.defineProperty(Array.prototype, 'flat', {
      value() {
        return this.reduce((acc, val) => acc.concat(val), []);
      },
    });
  }

  useEffect(()=> {
    console.log('REACT_APP_PROD_PATH:::', process.env.REACT_APP_PROD_PATH);
  },[]);


  return (
    <React.Fragment>
      <StyledEngineProvider injectFirst>
        <DevWindowBindings/>
        <CssBaseline/>
        <ThemeProvider theme={myTheme}>
          <Box className={classes.page_container}>
            <BrowserRouter>
              <Routes>
                <Route path={allConstants.PATH.COMPANY_BLOCKED} element={<BlockedPage/>}/>
                <Route path={allConstants.PATH.SERVER_REDIRECT} element={<ServerRedirect/>}/>
                <Route path={allConstants.PATH.ACCESS_DENIED} element={<AccessDenied/>}/>
                <Route path={allConstants.PATH.NO_ACCESS} element={<NoAccess/>}/>
                <Route path={allConstants.PATH.COMPANY_EXISTS} element={<CompanyExists/>}/>
                <Route path={allConstants.PATH.CHEERS_CONNECTION_REQUIRED} element={<CheersConnectionRequiredPage/>}/>
                <Route path={allConstants.PATH.LOGIN} element={
                  <AnonymousRoute><LoginPage/></AnonymousRoute>}/>
                <Route path={allConstants.PATH.SIGN_UP} element={
                  <SignupPage/>}/>
                <Route path="/"
                  element={
                    <PrivateRoute>
                      <Home/>
                    </PrivateRoute>
                  }
                />
                <Route path={allConstants.PATH.COMPANY}
                  element={
                    <PrivateRoute>
                      <CompanyView/>
                    </PrivateRoute>
                  }
                >
                  {/*<Route path={`:${allConstants.PATH.SUB_ROUTE_EDIT}`}*/}
                  {/*  element={*/}
                  {/*    <PrivateRoute>*/}
                  {/*      <Company/>*/}
                  {/*    </PrivateRoute>*/}
                  {/*  }*/}
                  {/*>*/}
                  {/*</Route>*/}
                </Route>
                <Route path={allConstants.PATH.REGISTER_COMPANY}
                  element={
                    <RegisterCompany/>
                  }
                >
                </Route>
                <Route path={allConstants.PATH.COMPANY_PENDING}
                  element={
                    <PrivateRoute>
                      <CompanyUnderReview/>
                    </PrivateRoute>
                  }
                >
                </Route>

                <Route path={allConstants.PATH.ALL_COMPANIES}
                  element={
                    <PrivateiPermitRoute>
                      <AllCompaniesView/>
                    </PrivateiPermitRoute>
                  }
                />

                <Route path={allConstants.PATH.USERS}
                  element={
                    <PrivateRoute>
                      <AllUsersView/>
                    </PrivateRoute>
                  }
                />
                <Route path={allConstants.PATH.USER}
                  element={
                    <PrivateRoute>
                      <UserView/>
                    </PrivateRoute>
                  }
                />
                <Route path={allConstants.PATH.CREATE_JOB}
                  element={
                    <PrivateRoute>
                      <CreateJobView/>
                    </PrivateRoute>
                  }
                />
                <Route path={allConstants.PATH.ADMIN_PAGE}
                  element={
                    <PrivateiPermitRoute>
                      <AdminPage/>
                    </PrivateiPermitRoute>
                  }
                />
                <Route path={allConstants.PATH.ADMIN_PAGE_USER}
                  element={
                    <PrivateiPermitRoute>
                      <IpermitUserView/>
                    </PrivateiPermitRoute>
                  }
                />
                <Route path={allConstants.PATH.JOBS}
                  element={
                    <PrivateRoute>
                      <AllJobsView/>
                    </PrivateRoute>
                  }
                />
                <Route path={allConstants.PATH.ANALYTICS} element={
                  <PrivateRoute>
                    <AnalyticsView/>
                  </PrivateRoute>
                }/>

                <Route path={allConstants.PATH.PRODUCTS}
                  element={
                    <PrivateiPermitRoute>
                      <AllProductsView/>
                    </PrivateiPermitRoute>
                  }
                />
                <Route path={allConstants.PATH.TAGS}
                  element={
                    <PrivateiPermitRoute>
                      <AllTagsView/>
                    </PrivateiPermitRoute>
                  }
                />
                <Route path={allConstants.PATH.MODULE}
                  element={
                    <PrivateiPermitRoute>
                      <ModuleView/>
                      {/*<AllInspectionsView/>*/}
                    </PrivateiPermitRoute>
                  }
                />
                <Route path={allConstants.PATH.MODULE_RECORD}
                  element={
                    <PrivateiPermitRoute>
                      <IpermitJobView/>
                    </PrivateiPermitRoute>
                  }
                />
                <Route path={allConstants.PATH.CITIES}
                  element={
                    <PrivateiPermitRoute>
                      <AllCitiesView/>
                    </PrivateiPermitRoute>
                  }
                />
                <Route path={allConstants.PATH.CITY}
                  element={
                    <PrivateiPermitRoute>
                      <CityView/>
                    </PrivateiPermitRoute>
                  }
                />
                <Route path={allConstants.PATH.ADD_CITY}
                  element={
                    <PrivateiPermitRoute>
                      <CityView add={true}/>
                    </PrivateiPermitRoute>
                  }
                />
                <Route path={allConstants.PATH.RATERS}
                  element={
                    <PrivateiPermitRoute>
                      <AllRatersView/>
                    </PrivateiPermitRoute>
                  }
                />
                <Route path={allConstants.PATH.RATER}
                  element={
                    <PrivateiPermitRoute>
                      <RaterView />
                    </PrivateiPermitRoute>
                  }
                />
                <Route path={allConstants.PATH.INSPECTION_SCHEDULE}
                  element={
                    <PrivateiPermitRoute>
                      <InspectionScheduleView />
                    </PrivateiPermitRoute>
                  }
                />
              </Routes>

            </BrowserRouter>
            <CustomToast/>
          </Box>
        </ThemeProvider>
      </StyledEngineProvider>
    </React.Fragment>

  );
};
export default App;
